import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, forgetLoginData, isServerDown,
} from '@/auth/utils'
import errorPages from '@/router/routes/error-pages'
import auth from '@/router/routes/auth'
import { isDemoHost, isDevelopment, platformName } from '@core/utils/utils'
import appsMarket from '@/router/routes/appsMarket'
import menus from '@/router/routes/menus'
import affiliates from '@/router/routes/affiliates'
import coupons from '@/router/routes/coupons'
import purchases from '@/router/routes/purchases'
import orders from './routes/orders'
import products from './routes/products'
import customers from './routes/customers'
import settings from './routes/settings'
import control from './routes/control'

import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const developmentOnlyRoute = isDevelopment() ? [
  ...apps,
  ...dashboard,
  ...pages,
  ...chartsMaps,
  ...formsTable,
  ...uiElements,
  ...others,
] : []
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/pages/index/Index.vue'),
      meta: {
        pageTitle: 'pages.home',
      },
    },
    ...orders,
    ...products,
    ...customers,
    ...affiliates,
    ...coupons,
    ...settings,
    ...control,
    ...purchases,
    ...menus,
    ...auth,
    ...errorPages,
    ...appsMarket,

    ...developmentOnlyRoute,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    if (isDevelopment()) {
      console.error('Unauthorized to navigate to:', to)
    }
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      forgetLoginData()
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  // Redirect if uses demo host
  if (to.meta.redirectIfDemoHost && isDemoHost()) {
    return next({ name: 'auth-login' })
    // return next(window.location.toString().replace(demoHost(), dashboardHost()))
  }

  const exceptRoutes = [
    'error-404',
    'network-error',
    'misc-not-authorized',
    'auth-login',
  ]
  // Server-Down check
  if (isServerDown()) {
    if (!exceptRoutes.filter(r => r === to.name).length) {
      return next({ name: 'network-error' })
    }
  }

  let basePageTitle = Vue.$trans('pages.index').replace('{platform}', platformName())
  if (to?.meta?.pageTitle) {
    basePageTitle += ` - ${Vue.$trans(to.meta.pageTitle)}`
  }
  window.document.title = basePageTitle

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
