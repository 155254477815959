export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      redirectIfDemoHost: true,
    },
  },
  {
    path: '/register/continue/:process/:token',
    name: 'auth-continue-registration',
    component: () => import('@/views/pages/authentication/ContinueRegistration.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      redirectIfDemoHost: true,
    },
  },
  {
    path: '/ready',
    name: 'auth-ready',
    component: () => import('@/views/pages/authentication/Ready.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth',
      redirectIfLoggedIn: false,
      redirectIfDemoHost: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      redirectIfDemoHost: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      redirectIfDemoHost: true,
    },
  },
  // {
  //   path: '/pages/account-setting',
  //   name: 'pages-account-setting',
  //   component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
  //   meta: {
  //     pageTitle: 'Account Settings',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Account Settings',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/pages/profile',
  //   name: 'pages-profile',
  //   component: () => import('@/views/pages/profile/Profile.vue'),
  //   meta: {
  //     pageTitle: 'Profile',
  //     breadcrumb: [
  //       {
  //         text: 'Pages',
  //       },
  //       {
  //         text: 'Profile',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
