import { $themeConfig } from '@themeConfig'

const updateCounterValue = (counter, value) => {
  localStorage.setItem(`menu-counter-${counter}`, value)

  const badge = document.getElementById(`menu_${counter}_count`)
  if (badge) {
    badge.innerText = value

    if (value > 0) {
      badge.classList.remove('d-none')
    } else {
      badge.classList.add('d-none')
    }
  }
}

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: localStorage.getItem('rtl') || $themeConfig.layout.isRTL || (localStorage.getItem('locale') === 'ar'),
      skin: localStorage.getItem('app-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
        counters: {
          orders: localStorage.getItem('menu-counter-orders'),
        },
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },
    localization: {
      arabicCountry: localStorage.getItem('arabic-country') || 'EG',
      currency: localStorage.getItem('currency') || 'EGP',
      currencyLocale: localStorage.getItem('currency-locale') || 'en-US',
    },
  },
  getters: {
  },
  mutations: {
    UPDATE_RTL(state, val) {
      state.layout.isRTL = val
      // Update value in localStorage
      localStorage.setItem('rtl', val)
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update value in localStorage
      localStorage.setItem('app-skin', skin)

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_MENU_COUNTER_CONFIG(state, obj) {
      Object.assign(state.layout.menu.counters, obj)
      for (const k in obj) {
        const v = parseInt(obj[k], 10)

        updateCounterValue(k, v)
      }
    },
    // Localization
    SET_ARABIC_COUNTRY(state, val) {
      state.localization.arabicCountry = val
      localStorage.setItem('arabic-country', val)
    },
  },
  actions: {},
}
