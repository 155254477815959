<template>
  <i18n-n
    :value="finalValue"
    format="currency"
  >
    <template v-slot:currency="slotProps">
      <span style="font-weight: bold">{{  $t(currencyPrefix) }}{{ $t(`${currency || slotProps.currency}`) }}</span>
    </template>
    <template v-slot:integer="slotProps">
      <span style="font-weight: bold">{{ slotProps.integer }}</span>
    </template>
    <template v-slot:group="slotProps">
      <span style="font-weight: bold">{{ slotProps.group }}</span>
    </template>
    <template v-slot:decimal="slotProps">
      <span
        v-if="decimals"
        style="font-weight: bold"
      >{{ slotProps.decimal }}</span>
    </template>
    <template v-slot:fraction="slotProps">
      <span
        v-if="decimals"
        style="font-weight: bold"
      >{{ slotProps.fraction }}</span>
    </template>
  </i18n-n>
</template>

<script>
import { getLocaleCurrency } from '@core/utils/utils'

export default {
  name: 'Money',
  components: {

  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    fraction: {
      type: Boolean,
      default: true,
    },
    short: {
      type: Boolean,
      default: true,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    forceDecimals: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: () => getLocaleCurrency(),
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  computed: {
    numericValue() {
      return parseFloat(this.value.toFixed(this.decimals))
    },
    shortingLevel() {
      if (this.short) {
        const val = this.numericValue
        if (val >= 1e6) return 2
        if (val >= 1e3) return 1
      }
      return 0
    },
    finalValue() {
      return parseFloat((this.shortingLevel ? this.numericValue / (1000 ** this.shortingLevel) : this.numericValue).toFixed(this.decimals))
    },
    currencyPrefix() {
      return ['', 'K', 'M'][this.shortingLevel]
    },
  },
  methods: {
    fractionRounding(value, decimals) {
      return (parseFloat(`0.${value}`)).toFixed(decimals).replace('0.', '')
    },
  },
}
</script>

<style lang="scss">

</style>
