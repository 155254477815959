export default [
  // *===============================================---*
  // *--------- Products -------------------------------*
  // *===============================================---*
  {
    path: '/products/list',
    name: 'products.list',
    component: () => import('@/views/models/products/list/ProductsList.vue'),
    meta: {
      pageTitle: 'modules.products.p_c',
      breadcrumb: [
        {
          text: 'modules.products.pages.list',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/products/view/:id',
  //   name: 'products.view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  {
    path: '/products/add/',
    name: 'products.add',
    component: () => import('@/views/models/products/add/ProductAdd.vue'),
    meta: {
      pageTitle: 'modules.products.pages.add',
      navActiveLink: 'products.list',
      breadcrumb: [
        {
          text: 'modules.products.p_c',
          to: { name: 'products.list' },
        },
        {
          text: 'modules.products.pages.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/edit/:id',
    name: 'products.edit',
    component: () => import('@/views/models/products/add/ProductAdd.vue'),
    meta: {
      pageTitle: 'modules.products.pages.edit',
      navActiveLink: 'products.list',
      breadcrumb: [
        {
          text: 'modules.products.p_c',
          to: { name: 'products.list' },
        },
        {
          text: 'modules.products.pages.edit',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- Products Categories --------------------*
  // *===============================================---*
  {
    path: '/products-categories/list',
    name: 'products_categories.list',
    component: () => import('@/views/models/product_categories/list/ProductCategoryList.vue'),
    meta: {
      pageTitle: 'modules.product_categories.p_c',
    },
  },
  // {
  //   path: '/products-categories/view/:id',
  //   name: 'products_categories.view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  // {
  //   path: '/products-categories/edit/:id',
  //   name: 'products_categories.edit',
  //   component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  // },
]
