export default {
  namespaced: true,
  state: {
    isEditorMenuCollapsed: localStorage.getItem('editor-menu-collapsed') ? localStorage.getItem('editor-menu-collapsed') === 'true' : false,
  },
  getters: {},
  mutations: {
    UPDATE_EDITOR_MENU_COLLAPSED(state, val) {
      state.isEditorMenuCollapsed = val

      localStorage.setItem('menu-collapsed', val)
    },
  },
  actions: {},
}
