<template>
  <div>
    <b-form-file
      :id="`input-${$attrs.id}`"
      ref="refInputEl"
      v-model="localeValue"
      :size="size"
      accept=".csv"
      :disabled="isLoading"
      :state="ariaInvalid ? false : null"
      :browse-text="$t('Browse')"
      :placeholder="$t('No file chosen')"
    />
    <b-card-text>{{ description }}</b-card-text>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { t } from '@core/libs/i18n/utils'

import {
  BCardText, BFormFile,
} from 'bootstrap-vue'
import { ref, watch } from 'vue'
import { upload } from '@/views/models/common/useFileStore'

import { storagePath, toast } from '@core/utils/utils'

const URL = window.URL || window.webkitURL

export default {
  components: {
    BCardText,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: [File, String],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
    description: {
      type: [String, null],
      default: null,
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    sizeLimit: {
      type: Number,
      default: 5 * 1024 * 1024, // 1MB
    },
    size: {
      type: String,
      default: 'lg',
    },
    ariaInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myFiles: [],
      currentValue: this.value || null,
      coverId: null,
    }
  },

  computed: {
    src() {
      return this.value ? storagePath(this.value) : null
    },
  },
  watch: {
  },
  created() {
    //
  },
  methods: {
    updateValue() {
      /*
      this.currentValue = this.$refs.pond.getFiles().filter(image => image.status === 2 || image.status === 5).map(file => file.serverId)
      this.$listeners.change(this.currentValue)

      // Update cover image
      const cover = this.$refs.pond.getFiles().filter(image => image.status === 2 || image.status === 5)?.[0]
      if (cover && this.coverId !== cover.id) {
        this.coverId = cover.id
        document.getElementsByClassName('filepond--item').forEach(elm => {
          if (elm.id === `filepond--item-${cover.id}`) {
            elm.classList.add('filepond--cover')
          } else {
            elm.classList.remove('filepond--cover')
          }
        })
      }
       */
    },
  },
  setup(props, ctx) {
    const refInputEl = ref(null)
    const localeValue = ref(null)
    const isFileUploadInProgress = ref(false)
    const isUploadFailed = ref(false)
    const errorMsg = ref(null)

    const onClear = () => {
      localeValue.value = null
      refInputEl.value.files = []
      ctx.emit('input', null)
    }

    const onFileSelected = fileObj => {
      if (!fileObj) {
        ctx.emit('input', fileObj)
      }
      isFileUploadInProgress.value = true
      isUploadFailed.value = false

      if (fileObj && fileObj.size) {
        if (fileObj.size > props.sizeLimit) {
          isFileUploadInProgress.value = false
          isUploadFailed.value = true
          errorMsg.value = 'File size is larger than allowed limit'
          return
        }

        if (!props.autoUpload) {
          ctx.emit('input', fileObj)
          return
        }

        upload(ctx, fileObj, false)
          .then(response => {
            if (response.data.success !== true) {
              throw new Error('File upload failed')
            }

            // localeValue.value = response.data.data
            ctx.emit('input', response.data.data)
            isFileUploadInProgress.value = false
          })
          .catch(error => {
            console.log(error)
            isFileUploadInProgress.value = false
            isUploadFailed.value = true
            errorMsg.value = null // TODO: Fill with error message
            if (error.response?.data?.message) {
              const level = error.response.status === 422 ? 'warning' : 'danger'
              const title = typeof (t) === 'function' ? t('message.generic_validation_error') : 'Error'
              toast(level, title, error.response?.data?.message)
            }
          })
      } else {
        isFileUploadInProgress.value = false
        isUploadFailed.value = false
        errorMsg.value = null
      }
    }

    const onReset = () => {
      localeValue.value = props.value
    }

    // File upload
    // const selectedFile = ref(null)

    // watch(() => refInputEl.value, uploadSelectedFile)
    watch(() => props.value, () => {
      // If autoUpload is enabled, then the final value will be string, which makes error in v-form-file component
      if (!props.autoUpload) {
        onReset()
      }
    })
    watch(() => localeValue.value, newValue => {
      onFileSelected(newValue)

      // TODO: In backend, handle image settings: Change path and append prefix @media:
    })

    return {
      refInputEl,
      onFileSelected,
      onReset,
      onClear,
      localeValue,
      isLoading: isFileUploadInProgress,
      isFailed: isUploadFailed,
      error: errorMsg,
    }
  },
}
</script>

<style lang="scss">
</style>
