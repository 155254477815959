<template>

  <file-pond
    :id="$attrs.id"
    ref="pond"
    name="media"
    :allow-multiple="true"
    :allow-reorder="true"
    accepted-file-types="image/jpeg, image/png"
    :server="serverConfig"
    :files="initialFiles"
    v-bind="{...localizedStrings, ...options, /*, ...$attrs*/}"
    credits=""
    dir="ltr"

    @init="handleFilePondInit"
    @updatefiles="handleUpdateFile"
    @addfile="handleAddFile"
    @processfile="handleFileProcessedEvent"
    @reorderfiles="handleReorderFiles"
  />
  <!-- v-on="$listeners" -->

<!--  <vue-dropzone-->
<!--    :id="$attrs.id"-->
<!--    ref="myVueDropzone"-->
<!--    v-model="currentValue"-->
<!--    :name="$attrs.id"-->
<!--    :state="errors.length > 0 ? false:null"-->
<!--    v-bind="$attrs"-->
<!--    :options="dropzoneOptions"-->
<!--    v-on="$listeners"-->
<!--  />-->
</template>

<script>

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

import arStrings from 'filepond/locale/ar-ar'
import enStrings from 'filepond/locale/en-en'

// Import FilePond styles

import 'filepond/dist/filepond.min.css'
// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
// Import image preview and file type validation plugins

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { env } from '@core/utils/utils'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)

// const demo = require('@/assets/images/banner/banner-21.jpg').replace('/img/', 'img/')

export default {
  components: {
    FilePond,
  },
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      myFiles: [],
      currentValue: this.value,
      coverId: null,
    }
  },

  computed: {
    serverConfig() {
      // https://httpbin.org/post
      // https://via.placeholder.com/800x800.png?text=

      const BACKEND_HOST = env('VUE_APP_BACKEND_API_HOST', 'http://api.localhost')
      const MEDIA_UPLOAD_ENDPOINT = env('VUE_APP_MEDIA_UPLOAD_ENDPOINT', 'dashboard/v1/media')
      const mediaWriteUrl = `${BACKEND_HOST}/${MEDIA_UPLOAD_ENDPOINT}`
      const mediaReadUrl = `${mediaWriteUrl}/` // privateMediaPath()

      return {
        url: '',
        // process: 'https://httpbin.org/post',
        // process: 'https://27d9f0c2-0f08-494b-b339-8a9afaaecbbc.mock.pstmn.io/upload',
        // process: mediaWriteUrl,

        process: {
          url: mediaWriteUrl,
          method: 'POST',
          withCredentials: true,
          onload: response => {
            const responseJson = JSON.parse(response)
            return responseJson.data
          },
          // onerror: response => {
          //   console.log('onerror', resposne)
          //   return response.data
          // },
          // ondata: formData => {
          //   console.log('ondata', formData)
          //   formData.append('Hello', 'World')
          //   return formData
          // },
        },

        revert: {
          url: `${mediaWriteUrl}/deleteOne`,
          method: 'DELETE',
          withCredentials: true,
        },

        // url: 'http://cp.test',
        fetch: '?fetch=',
        // load: '?load=',
        // load: 'http://demo.localhost/',
        // load: mediaReadUrl,
        load: {
          url: mediaReadUrl,
          method: 'GET',
          headers: {
            'X-STORE': localStorage.getItem('storeId'),
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        },
        withCredentials: true,
        headers: {
          'X-STORE': localStorage.getItem('storeId'),
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    },
    options() {
      return {
        labelIdle: `${this.$t('Drag & Drop your images')} ${this.$t('or')} <span class="filepond--label-action">${this.$t('Browse')}</span>`,
      }
    },
    localizedStrings() {
      return this.$i18n.locale === 'ar' ? arStrings : enStrings
    },
    initialFiles() {
      const files = []
      let i
      for (i in this.value) {
        files.push({
          // the server file reference
          source: this.value[i],

          // set type to local to indicate an already uploaded file
          options: {
            type: 'local',
          },
        })
      }
      return files
    },
  },
  watch: {
  },
  methods: {
    handleFilePondInit(args) {
      //
    },
    handleUpdateFile(args) {
      // console.log('FilePond files updated', args)
      this.updateValue()
    },
    handleReorderFiles(args) {
      // console.log('handleReorderFiles', args)
      this.updateValue()
    },
    handleAddFile(args) {
      // console.log('FilePond file has been added', args)
    },
    handleFileProcessedEvent() {
      // this.$listeners.input(this.$refs.pond.getFiles())
      this.updateValue()
    },
    updateValue() {
      this.currentValue = this.$refs.pond.getFiles().filter(image => image.status === 2 || image.status === 5).map(file => file.serverId)
      this.$listeners.change(this.currentValue)

      // Update cover image
      const cover = this.$refs.pond.getFiles().filter(image => image.status === 2 || image.status === 5)?.[0]
      if (cover && this.coverId !== cover.id) {
        this.coverId = cover.id
        document.getElementsByClassName('filepond--item').forEach(elm => {
          if (elm.id === `filepond--item-${cover.id}`) {
            elm.classList.add('filepond--cover')
          } else {
            elm.classList.remove('filepond--cover')
          }
        })
      }
    },
  },
  setup() {
    //
  },
}
</script>

<style lang="scss">
/* Styling: Font and colors */
.filepond--root {
  font-family: inherit;
}

.filepond--root.filepond--hopper {
  margin: 0;
}

.filepond--wrapper {
  border: 2px dashed;
}

[dir] .filepond--panel[data-scalable="true"] {
  background-color: #f3f3f3;
}
[dir] body.dark-layout {
  .filepond--panel-root {
    background-color: #1f2638 !important;
  }
}

/* Grid layout */
.filepond--item {
  width: calc(50% - 0.5em);
}
@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
  }
}
/* TODO: Fix rtl of list */
/* RTL fix */
[dir="rtl"] {
  .filepond--image-clip {
    direction: ltr !important;
  }

  ul.filepond--list {
    direction: ltr !important;
  }

  .filepond--file [data-align*="right"] {
    left: unset;
  }
  .filepond--file {
    .filepond--file-status {
      margin-left: auto;
      margin-right: 2.25em;
    }
  }
  [data-filepond-item-state*="error"] .filepond--file-info {
    margin-left: unset;
  }
  .filepond--file-info {
    text-align: left;
  }
  [data-filepond-item-state*="invalid"] .filepond--file-info {
    margin-left: unset;
  }
  filepond--file-status-sub {
    direction: rtl;
  }
}

/* Show Cover icon */
//[data-filepond-item-state="idle"]:first-child::after{
.filepond--item.filepond--cover::after{
  font-family: 'Material Symbols Outlined',serif;
  content: 'photo_album';
  width: 1.5em;
  height: 1.5em;
  font-size: 21px;
  vertical-align: middle;
  text-align: center;
  border-radius: 0 50% 50% 0;
  background-color: rgba(0,0,0,.5);
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 2px;
}

[dir="rtl"] .filepond--item.filepond--cover::after{
  border-radius: 0 50% 50% 0 !important;
}

/* Validation Error */
[dir] .filepond--wrapper.is-invalid {
  border-color: #ea5455;
}
</style>
