export default [
  // *===============================================---*
  // *--------- Subscriptions -------------------------------*
  // *===============================================---*
  {
    path: '/purchases',
    name: 'purchases.list',
    component: () => import('@/views/models/purchases/list/PurchaseList.vue'),
    meta: {
      pageTitle: 'modules.purchases.p_c',
      breadcrumb: [
        {
          text: 'modules.purchases.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchases/checkout',
    name: 'purchases.checkout',
    component: () => import('@/views/models/purchases/create/PurchaseCheckout.vue'),
    meta: {
      pageTitle: 'modules.purchases.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.purchases.p_c',
          active: false,
          to: { name: 'purchases.list' },
        },
        {
          text: 'modules.purchases.pages.checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchases/:id',
    name: 'purchases.view',
    component: () => import('@/views/models/purchases/view/PurchaseView.vue'),
    meta: {
      pageTitle: 'modules.purchases.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.purchases.p_c',
          active: false,
          to: { name: 'purchases.list' },
        },
        {
          text: 'modules.purchases.pages.view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchases/confirmation/:id',
    name: 'purchases.confirmation',
    component: () => import('@/views/models/purchases/view/PurchaseConfirmation.vue'),
    meta: {
      pageTitle: 'modules.purchases.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.purchases.p_c',
          active: false,
          to: { name: 'purchases.list' },
        },
        {
          text: 'modules.purchases.pages.confirmation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchases/failure/:id',
    name: 'purchases.failure',
    component: () => import('@/views/models/purchases/view/PurchaseFailure.vue'),
    meta: {
      pageTitle: 'modules.purchases.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.purchases.p_c',
          active: false,
          to: { name: 'purchases.list' },
        },
        {
          text: 'modules.purchases.pages.failure',
          active: true,
        },
      ],
    },
  },
]
