export default [
  // *===============================================---*
  // *--------- Customers -------------------------------*
  // *===============================================---*
  {
    path: '/customers/list',
    name: 'customers.list',
    component: () => import('@/views/models/customers/list/CustomerList.vue'),
    meta: {
      pageTitle: 'modules.customers.p_c',
      breadcrumb: [
        {
          text: 'modules.customers.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/view/:id',
    name: 'customers.view',
    component: () => import('@/views/models/customers/view/CustomerView.vue'),
    meta: {
      pageTitle: 'modules.customers.pages.view',
      navActiveLink: 'customers.list',
      breadcrumb: [
        {
          text: 'modules.customers.p_c',
          to: { name: 'customers.list' },
        },
        {
          text: 'modules.customers.pages.view',
          active: true,
        },
      ],
    },
  },
]
