export default {
  name: 'theme_editor',

  translatedAttributes: [],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => item,

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    ...data,
    // id: data.block_id,
    // block_template_id: data.block_template_id,
    // settings: data.settings,
    // is_active: !!data.is_active,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    // TODO: Display each setting error
    settings: errors.settings,
  }),
}
