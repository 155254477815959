export default [

  // *===============================================---*
  // *-------------------- Menus------------------------*
  // *===============================================---*
  {
    path: '/menus',
    name: 'menus',
    component: () => import('@/views/models/menus/MenusIndex.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'modules.menus.p_c',
      navActiveLink: 'generalSettings',
      breadcrumb: [
        {
          text: 'modules.settings.p_c',
          to: { name: 'generalSettings', params: { category: 'store', subcategory: 'general' } },
        },
        {
          text: 'modules.settings.pages.menus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/menus/:menu',
    name: 'menus-links',
    component: () => import('@/views/models/menus/MenusIndex.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      pageTitle: 'modules.menus.p_c',
      navActiveLink: 'generalSettings',
      breadcrumb: [
        {
          text: 'modules.settings.p_c',
          to: { name: 'generalSettings', params: { category: 'store', subcategory: 'general' } },
        },
        {
          text: 'modules.settings.pages.menus',
          active: true,
        },
      ],
    },
  },
]
