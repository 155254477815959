import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

Vue.use(VueTelInput, {
  autoFormat: true,
  autoDefaultCountry: true,
  dropdownOptions: {
    showFlags: true,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
    showSearchBox: true,
  },
  ignoredCountries: ['il'],
  // inputOptions: {}, // This will be filled directly in the input component
  mode: 'national',
  preferredCountries: ['eg', 'sa', 'ae', 'kw', 'qa'],
}) // Define default global options here (optional)
