import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import MaterialIcon from '@core/components/material-icon/MaterialIcon.vue'
import IInput from '@core/components/i-input/IInputComponent.vue'
import Money from '@core/components/monetary/Money.vue'
import ServerUnreachable from '@/views/pages/miscellaneous/ServerUnreachable.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(MaterialIcon.name, MaterialIcon)
Vue.component(IInput.name, IInput)
Vue.component(Money.name, Money)
Vue.component(ServerUnreachable.name, ServerUnreachable)

// TODO: Record server-unreachable error in storage, and force it's route till issue being eliminated
