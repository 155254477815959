import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import settingModel from '@/views/models/settings/general-settings/settingModel'

const {
  fetchOne,
  updateAttribute,
} = useModelStoreModule(settingModel)

export default {
  namespaced: true,
  state: {
    // data: JSON.parse(localStorage.getItem('storeData')),
    // release: {
    //   version: localStorage.getItem('store.release.version') || '1.0',
    // },
  },
  getters: {},
  mutations: {
    UPDATE(state, { code, value }) {
      updateAttribute(null, { itemId: code, attribute: 'value', value })
    },
  },
  actions: {
    fetchOne,
    update: (ctx, { code, value }) => updateAttribute(ctx, { itemId: code, attribute: 'value', value }),
  },
}
