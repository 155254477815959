import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getBrowserLocale from '@/libs/i18n/get-browser-locale-examples'
// import useAppConfig from '@core/app-config/useAppConfig'

Vue.use(VueI18n)

export const supportedLocales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i).keys().map(filename => filename.replace('./', '').replace('.json', ''))

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function supportedLocalesInclude(locale) {
  return Object.values(supportedLocales).includes(locale)
}

function computeCurrentLocale() {
  // TODO: Visit src/@core/utils/utils.js
  const storedLocale = localStorage.getItem('locale')
  if (storedLocale) return storedLocale

  // const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  // const locale = supportedLocalesInclude(browserLocale) ? browserLocale : (process.env.VUE_APP_I18N_LOCALE || 'ar')
  const locale = 'ar'

  localStorage.setItem('locale', locale)
  return locale
}

function computeCurrentCurrency() {
  const shopCurrency = localStorage.getItem('store.currency')
  if (shopCurrency) {
    return shopCurrency
  }
  const shop = JSON.parse(localStorage.getItem('storeData'))
  return shop?.currency || 'SAR'
}

export function setLocale(locale, $i18n) {
  if (!supportedLocalesInclude(locale)) return

  // eslint-disable-next-line no-param-reassign
  $i18n.locale = locale
  localStorage.setItem('locale', locale)
  if (locale === 'ar') {
    localStorage.setItem('rtl', '1')
  } else {
    localStorage.removeItem('rtl')
  }
  window.location.reload()
}

export default new VueI18n({
  locale: computeCurrentLocale(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: computeCurrentCurrency(),
        currencyDisplay: 'symbol',
      },
    },
    ar: {
      currency: {
        style: 'currency',
        currency: computeCurrentCurrency(),
        currencyDisplay: 'symbol',
      },
    },
  },
})
