export default [
  // *===============================================---*
  // *--------- Affiliates -------------------------------*
  // *===============================================---*
  {
    path: '/affiliates/list',
    name: 'affiliates.list',
    component: () => import('@/views/models/affiliates/list/AffiliateList.vue'),
    meta: {
      pageTitle: 'modules.affiliates.p_c',
      breadcrumb: [
        {
          text: 'modules.affiliates.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/affiliates/view/:id',
    name: 'affiliates.view',
    component: () => import('@/views/models/affiliates/view/AffiliateView.vue'),
    meta: {
      pageTitle: 'modules.affiliates.pages.view',
      navActiveLink: 'affiliates.list',
      breadcrumb: [
        {
          text: 'modules.affiliates.p_c',
          to: { name: 'affiliates.list' },
        },
        {
          text: 'modules.affiliates.pages.view',
          active: true,
        },
      ],
    },
  },
]
