<template>
  <b-form-textarea
    :id="$attrs.id"
    v-model="currentValue"
    :name="$attrs.id"
    :state="errors.length > 0 ? false:null"
    v-bind="$attrs"
    v-on="$listeners"
  ><slot /></b-form-textarea>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      currentValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
  },
}
</script>

<style lang="scss">
</style>
