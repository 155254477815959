import { getLocale } from '@core/utils/utils'

const categoriesNameMap = getLocale() === 'ar' ? {
  'store-product': 'منتجات المتجر',
  'onetime-products-import': 'استيراد المنتجات لمرة واحدة',
  'continuous-products-import': 'استيراد المنتجات بإستمرار (مزامنة)',
  marketing: 'التسويق',
  'campaigns-tracking': 'تتبع الحملات',
  'customer-service': 'خدمة العملاء',
  'shipping-and-delivery': 'الشحن والتوصيل',
  'accounting-invoicing': 'المحاسبة والفواتير',
} : {
  'store-product': 'Store Products',
  'onetime-products-import': 'One-Time Products Import',
  'continuous-products-import': 'Continuous Products Import (Sync)',
  marketing: 'Marketing',
  'campaigns-tracking': 'Campaigns Tracking',
  'customer-service': 'Customer Service',
  'shipping-and-delivery': 'Shipping and Delivery',
  'accounting-invoicing': 'Accounting and Invoicing',
}

export default [
  // *===============================================---*
  // *--------- Apps Market-----------------------------*
  // *===============================================---*
  {
    path: '/apps',
    name: 'apps-index',
    component: () => import('@/views/models/apps/AppsIndex.vue'),
    meta: {
      pageTitle: 'modules.apps.p_c',
      breadcrumb: [
        {
          text: 'modules.apps.p_c',
          active: false,
        },
        {
          text: 'modules.apps.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/:category',
    name: 'apps-category',
    component: () => import('@/views/models/apps/AppsCategory.vue'),
    meta: {
      pageTitle: 'modules.apps.p_c',
      navActiveLink: 'apps-index',
      breadcrumb: [
        {
          text: 'modules.apps.p_c',
          active: false,
          to: { name: 'apps-index' },
        },
        {
          text: '',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      to.meta.breadcrumb[1].text = (to.params.categoryName || categoriesNameMap[to.params.category] || to.params.category)
      next()
    },
  },
  {
    path: '/apps/:category/:app',
    name: 'apps-app',
    component: () => import('@/views/models/apps/AppsApp.vue'),
    meta: {
      pageTitle: 'modules.apps.p_c',
      navActiveLink: 'apps-index',
      breadcrumb: [
        {
          text: 'modules.apps.p_c',
          active: false,
          to: { name: 'apps-index' },
        },
        {
          text: ' ',
          active: false,
          to: { name: 'apps-index' },
        },
        {
          text: '',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      to.meta.breadcrumb[1].to = { name: 'apps-category', params: { category: to.params.category, categoryName: to.params?.categoryName || null } }
      to.meta.breadcrumb[1].text = (to.params.categoryName || categoriesNameMap[to.params.category] || to.params.category)
      // breadCrumbs[2].text = to.params.appName || to.params.app
      next()
    },
  },
]
