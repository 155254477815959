export default [
  // *===============================================---*
  // *--------- Subscriptions -------------------------------*
  // *===============================================---*
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/models/subscriptions/view/Index.vue'),
    meta: {
      pageTitle: 'modules.subscriptions.p_c',
      breadcrumb: [
        {
          text: 'modules.subscriptions.pages.view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pricing-plans',
    name: 'plans',
    component: () => import('@/views/models/subscriptions/create/PlansList.vue'),
    meta: {
      pageTitle: 'modules.subscriptions.p_c',
      breadcrumb: [
        {
          text: 'modules.subscriptions.p_c',
          active: false,
          to: { name: 'subscription' },
        },
        {
          text: 'modules.subscriptions.pages.plans',
          active: true,
        },
      ],
    },
  },
]
