<template>
  <div>
    <!--    <p>{{ currentValue }}</p>-->
    <!--    <p>{{ initialDialCode }}</p>-->
    <!--    <p>OBJ:{{ currentPhoneObject }}</p>-->
    <vue-tel-input
      v-model="currentValue"
      :state="errors.length > 0 ? false:null"
      v-bind="$attrs"
      :default-country="initialDialCode"
      :input-options="inputOptions"
      class="ltr"
      @input="(number, phoneObject) => currentPhoneObject = phoneObject"
    />

    <!--    @country-changed="country => initialDialCode = country.dialCode || ''"-->
    <!--    @input="(number, phoneObject) => initialPhone = phoneObject.nationalNumber || ''"-->
    <!--    v-on="$listeners"-->
  </div>

<!--  <div>-->

<!--    <b-form-input-->
<!--      :id="$attrs.id"-->
<!--      ref="inputRef"-->
<!--      v-model="currentValue"-->
<!--      :placeholder="$attrs.title"-->
<!--      :state="errors.length > 0 ? false:null"-->
<!--      :options="inputConfig"-->
<!--      type="text"-->
<!--      v-bind="$attrs"-->
<!--    />-->
<!--  </div>-->
<!--  v-bind="$attrs"-->
<!--  v-on="$listeners"-->

<!--    <br>-->
<!--    <code>-->
<!--      Props: {{ $props }}-->
<!--      <br>-->
<!--      Attrs: {{ $attrs }}-->
<!--      <br>-->
<!--      Listeners: {{ $listeners }}-->
<!--    </code>-->
</template>

<script>
import { ref } from 'vue'
import { VueTelInput } from 'vue-tel-input'

const countriesKeyedByDialCode = {
  1: { code: '1', country: 'CA', placeholder: '506 234 5678' },
  7: { code: '7', country: 'RU', placeholder: '8 912 345 67 89' },
  20: { code: '20', country: 'EG', placeholder: '0100 123 4567' },
  30: { code: '30', country: 'GR', placeholder: '691 234 5678' },
  31: { code: '31', country: 'NL', placeholder: '06 12345678' },
  32: { code: '32', country: 'BE', placeholder: '0470 12 34 56' },
  33: { code: '33', country: 'FR', placeholder: '06 12 34 56 78' },
  34: { code: '34', country: 'ES', placeholder: '612 34 56 78' },
  36: { code: '36', country: 'HU', placeholder: '06 20 123 4567' },
  39: { code: '39', country: 'IT', placeholder: '312 345 6789' },
  40: { code: '40', country: 'RO', placeholder: '0712 034 567' },
  41: { code: '41', country: 'CH', placeholder: '078 123 45 67' },
  43: { code: '43', country: 'AT', placeholder: '0664 123456' },
  44: { code: '44', country: 'GB', placeholder: '07400 123456' },
  45: { code: '45', country: 'DK', placeholder: '32 12 34 56' },
  46: { code: '46', country: 'SE', placeholder: '070 123 45 67' },
  47: { code: '47', country: 'SJ', placeholder: '412 34 567' },
  48: { code: '48', country: 'PL', placeholder: '512 345 678' },
  49: { code: '49', country: 'DE', placeholder: '01512 3456789' },
  51: { code: '51', country: 'PE', placeholder: '912 345 678' },
  52: { code: '52', country: 'MX', placeholder: '222 123 4567' },
  53: { code: '53', country: 'CU', placeholder: '05 1234567' },
  54: { code: '54', country: 'AR', placeholder: '011 15 2345 6789' },
  55: { code: '55', country: 'BR', placeholder: '11 96123 4567' },
  56: { code: '56', country: 'CL', placeholder: '2 2123 4567' },
  57: { code: '57', country: 'CO', placeholder: '321 1234567' },
  60: { code: '60', country: 'MY', placeholder: '012 345 6789' },
  61: { code: '61', country: 'CX', placeholder: '0412 345 678' },
  62: { code: '62', country: 'ID', placeholder: '0812 345 678' },
  63: { code: '63', country: 'PH', placeholder: '0905 123 4567' },
  64: { code: '64', country: 'NZ', placeholder: '021 123 4567' },
  65: { code: '65', country: 'SG', placeholder: '8123 4567' },
  81: { code: '81', country: 'JP', placeholder: '090 1234 5678' },
  82: { code: '82', country: 'KR', placeholder: '010 2000 0000' },
  86: { code: '86', country: 'CN', placeholder: '131 2345 6789' },
  91: { code: '91', country: 'IN', placeholder: '081234 56789' },
  92: { code: '92', country: 'PK', placeholder: '0301 2345678' },
  93: { code: '93', country: 'AF', placeholder: '070 123 4567' },
  94: { code: '94', country: 'LK', placeholder: '071 234 5678' },
  95: { code: '95', country: 'MM', placeholder: '09 212 3456' },
  98: { code: '98', country: 'IR', placeholder: '0912 345 6789' },
  212: { code: '212', country: 'MA', placeholder: '0650 123456' },
  213: { code: '213', country: 'DZ', placeholder: '0551 23 45 67' },
  218: { code: '218', country: 'LY', placeholder: '091 2345678' },
  220: { code: '220', country: 'GM', placeholder: '301 2345' },
  222: { code: '222', country: 'MR', placeholder: '22 12 34 56' },
  223: { code: '223', country: 'ML', placeholder: '65 01 23 45' },
  224: { code: '224', country: 'GN', placeholder: '601 12 34 56' },
  225: { code: '225', country: 'CI', placeholder: '01 23 45 67' },
  226: { code: '226', country: 'BF', placeholder: '70 12 34 56' },
  227: { code: '227', country: 'NE', placeholder: '93 12 34 56' },
  229: { code: '229', country: 'BJ', placeholder: '90 01 12 34' },
  230: { code: '230', country: 'MU', placeholder: '5251 2345' },
  231: { code: '231', country: 'LR', placeholder: '077 012 3456' },
  233: { code: '233', country: 'GH', placeholder: '023 123 4567' },
  234: { code: '234', country: 'NG', placeholder: '0802 123 4567' },
  236: { code: '236', country: 'CF', placeholder: '70 01 23 45' },
  237: { code: '237', country: 'CM', placeholder: '6 71 23 45 67' },
  238: { code: '238', country: 'CV', placeholder: '991 12 34' },
  240: { code: '240', country: 'GQ', placeholder: '222 123 456' },
  241: { code: '241', country: 'GA', placeholder: '06 03 12 34' },
  242: { code: '242', country: 'CG', placeholder: '06 123 4567' },
  243: { code: '243', country: 'CD', placeholder: '0991 234 567' },
  244: { code: '244', country: 'AO', placeholder: '923 123 456' },
  245: { code: '245', country: 'GW', placeholder: '955 012 345' },
  246: { code: '246', country: 'IO', placeholder: '380 1234' },
  248: { code: '248', country: 'SC', placeholder: '2 510 123' },
  249: { code: '249', country: 'SD', placeholder: '091 123 1234' },
  250: { code: '250', country: 'RW', placeholder: '0720 123 456' },
  251: { code: '251', country: 'ET', placeholder: '091 123 4567' },
  253: { code: '253', country: 'DJ', placeholder: '77 83 10 01' },
  254: { code: '254', country: 'KE', placeholder: '0712 123456' },
  257: { code: '257', country: 'BI', placeholder: '79 56 12 34' },
  258: { code: '258', country: 'MZ', placeholder: '82 123 4567' },
  261: { code: '261', country: 'MG', placeholder: '032 12 345 67' },
  262: { code: '262', country: 'RE', placeholder: '0692 12 34 56' },
  264: { code: '264', country: 'NA', placeholder: '081 123 4567' },
  265: { code: '265', country: 'MW', placeholder: '0991 23 45 67' },
  266: { code: '266', country: 'LS', placeholder: '5012 3456' },
  267: { code: '267', country: 'BW', placeholder: '71 123 456' },
  269: { code: '269', country: 'KM', placeholder: '321 23 45' },
  290: { code: '290', country: 'SH', placeholder: '51234' },
  291: { code: '291', country: 'ER', placeholder: '07 123 456' },
  297: { code: '297', country: 'AW', placeholder: '560 1234' },
  298: { code: '298', country: 'FO', placeholder: '211234' },
  299: { code: '299', country: 'GL', placeholder: '22 12 34' },
  350: { code: '350', country: 'GI', placeholder: '57123456' },
  351: { code: '351', country: 'PT', placeholder: '912 345 678' },
  352: { code: '352', country: 'LU', placeholder: '628 123 456' },
  353: { code: '353', country: 'IE', placeholder: '085 012 3456' },
  354: { code: '354', country: 'IS', placeholder: '611 1234' },
  355: { code: '355', country: 'AL', placeholder: '067 212 3456' },
  356: { code: '356', country: 'MT', placeholder: '9696 1234' },
  357: { code: '357', country: 'CY', placeholder: '96 123456' },
  358: { code: '358', country: 'FI', placeholder: '041 2345678' },
  359: { code: '359', country: 'BG', placeholder: '048 123 456' },
  370: { code: '370', country: 'LT', placeholder: '8 612 34567' },
  371: { code: '371', country: 'LV', placeholder: '21 234 567' },
  372: { code: '372', country: 'EE', placeholder: '5123 4567' },
  373: { code: '373', country: 'MD', placeholder: '0621 12 345' },
  374: { code: '374', country: 'AM', placeholder: '077 123456' },
  375: { code: '375', country: 'BY', placeholder: '8 029 491 19 11' },
  376: { code: '376', country: 'AD', placeholder: '312 345' },
  377: { code: '377', country: 'MC', placeholder: '06 12 34 56 78' },
  381: { code: '381', country: 'RS', placeholder: '060 1234567' },
  382: { code: '382', country: 'ME', placeholder: '067 622 901' },
  385: { code: '385', country: 'HR', placeholder: '092 123 4567' },
  386: { code: '386', country: 'SI', placeholder: '031 234 567' },
  387: { code: '387', country: 'BA', placeholder: '061 123 456' },
  389: { code: '389', country: 'MK', placeholder: '072 345 678' },
  420: { code: '420', country: 'CZ', placeholder: '601 123 456' },
  423: { code: '423', country: 'LI', placeholder: '660 234 567' },
  500: { code: '500', country: 'GS', placeholder: null },
  501: { code: '501', country: 'BZ', placeholder: '622 1234' },
  502: { code: '502', country: 'GT', placeholder: '5123 4567' },
  504: { code: '504', country: 'HN', placeholder: '9123 4567' },
  505: { code: '505', country: 'NI', placeholder: '8123 4567' },
  506: { code: '506', country: 'CR', placeholder: '8312 3456' },
  507: { code: '507', country: 'PA', placeholder: '6123 4567' },
  508: { code: '508', country: 'PM', placeholder: '055 12 34' },
  509: { code: '509', country: 'HT', placeholder: '34 10 1234' },
  590: { code: '590', country: 'MF', placeholder: '0690 00 12 34' },
  591: { code: '591', country: 'BO', placeholder: '71234567' },
  592: { code: '592', country: 'GY', placeholder: '609 1234' },
  593: { code: '593', country: 'EC', placeholder: '099 123 4567' },
  594: { code: '594', country: 'GF', placeholder: '0694 20 12 34' },
  595: { code: '595', country: 'PY', placeholder: '0961 456789' },
  596: { code: '596', country: 'MQ', placeholder: '0696 20 12 34' },
  599: { code: '599', country: 'CW', placeholder: '9 518 1234' },
  672: { code: '672', country: 'NF', placeholder: '3 81234' },
  673: { code: '673', country: 'BN', placeholder: '712 3456' },
  674: { code: '674', country: 'NR', placeholder: '555 1234' },
  675: { code: '675', country: 'PG', placeholder: '7012 3456' },
  677: { code: '677', country: 'SB', placeholder: '74 21234' },
  679: { code: '679', country: 'FJ', placeholder: '701 2345' },
  680: { code: '680', country: 'PW', placeholder: '620 1234' },
  682: { code: '682', country: 'CK', placeholder: '71 234' },
  683: { code: '683', country: 'NU', placeholder: '888 4012' },
  686: { code: '686', country: 'KI', placeholder: '72001234' },
  687: { code: '687', country: 'NC', placeholder: '75.12.34' },
  689: { code: '689', country: 'PF', placeholder: '87 12 34 56' },
  691: { code: '691', country: 'FM', placeholder: '350 1234' },
  692: { code: '692', country: 'MH', placeholder: '235 1234' },
  850: { code: '850', country: 'KP', placeholder: '0192 123 4567' },
  852: { code: '852', country: 'HK', placeholder: '5123 4567' },
  853: { code: '853', country: 'MO', placeholder: '6612 3456' },
  855: { code: '855', country: 'KH', placeholder: '091 234 567' },
  856: { code: '856', country: 'LA', placeholder: '020 23 123 456' },
  870: { code: '870', country: 'PN', placeholder: null },
  880: { code: '880', country: 'BD', placeholder: '01812 345678' },
  960: { code: '960', country: 'MV', placeholder: '771 2345' },
  961: { code: '961', country: 'LB', placeholder: '71 123 456' },
  962: { code: '962', country: 'JO', placeholder: '07 9012 3456' },
  964: { code: '964', country: 'IQ', placeholder: '0791 234 5678' },
  965: { code: '965', country: 'KW', placeholder: '500 12345' },
  966: { code: '966', country: 'SA', placeholder: '051 234 5678' },
  968: { code: '968', country: 'OM', placeholder: '9212 3456' },
  970: { code: '970', country: 'PS', placeholder: '0599 123 456' },
  971: { code: '971', country: 'AE', placeholder: '050 123 4567' },
  973: { code: '973', country: 'BH', placeholder: '3600 1234' },
  974: { code: '974', country: 'QA', placeholder: '3312 3456' },
  975: { code: '975', country: 'BT', placeholder: '17 12 34 56' },
  976: { code: '976', country: 'MN', placeholder: '8812 3456' },
  977: { code: '977', country: 'NP', placeholder: '984 1234567' },
  994: { code: '994', country: 'AZ', placeholder: '040 123 45 67' },
  995: { code: '995', country: 'GE', placeholder: '555 12 34 56' },
  996: { code: '996', country: 'KG', placeholder: '0700 123 456' },
  1242: { code: '+1-242', country: 'BS', placeholder: '242 359 1234' },
  1246: { code: '+1-246', country: 'BB', placeholder: '246 250 1234' },
  1264: { code: '+1-264', country: 'AI', placeholder: '264 235 1234' },
  1268: { code: '+1-268', country: 'AG', placeholder: '268 464 1234' },
  1345: { code: '+1-345', country: 'KY', placeholder: '345 323 1234' },
  1441: { code: '+1-441', country: 'BM', placeholder: '441 370 1234' },
  1473: { code: '+1-473', country: 'GD', placeholder: '473 403 1234' },
  1664: { code: '+1-664', country: 'MS', placeholder: '664 492 3456' },
  1670: { code: '+1-670', country: 'MP', placeholder: '670 234 5678' },
  1671: { code: '+1-671', country: 'GU', placeholder: '671 300 1234' },
  1684: { code: '+1-684', country: 'AS', placeholder: '684 733 1234' },
  1758: { code: '+1-758', country: 'LC', placeholder: '758 284 5678' },
  1767: { code: '+1-767', country: 'DM', placeholder: '767 225 1234' },
  1869: { code: '+1-869', country: 'KN', placeholder: '869 765 2917' },
  1876: { code: '+1-876', country: 'JM', placeholder: '876 210 1234' },
  35818: { code: '+358-18', country: 'AX', placeholder: '041 2345678' },
  441481: { code: '+44-1481', country: 'GG', placeholder: '07781 123456' },
  441534: { code: '+44-1534', country: 'JE', placeholder: '07797 712345' },
  441624: { code: '+44-1624', country: 'IM', placeholder: '07924 123456' },
  '0055': { code: '0055', country: 'BV', placeholder: null },
  1809: {
    code: '+1-809 and 1-829',
    country: 'DO',
    placeholder: '809 234 5678',
  },
  1829: {
    code: '+1-809 and 1-829',
    country: 'DO',
    placeholder: '809 234 5678',
  },
  1787: {
    code: '+1-787 and 1-939',
    country: 'PR',
    placeholder: '787 234 5678',
  },
  1939: {
    code: '+1-787 and 1-939',
    country: 'PR',
    placeholder: '787 234 5678',
  },
}
export default {
  components: {
    VueTelInput,
  },
  emits: ['update:dial-code', 'input', 'update:country-code', 'update:international-number'],
  props: {
    // id: {
    //   type: String,
    //   default: null,
    // },
    // title: {
    //   required: true,
    //   type: String,
    // },

    value: {
      type: [String, Number],
      default: '',
    },
    dialCode: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    inputOptions() {
      const currentDialCode = this.currentPhoneObject?.country?.dialCode
      const defaultPlaceholder = this.$t('inputs.mobile:placeholder')

      return {
        showDialCode: false,
        styleClasses: ['form-control'],
        placeholder: this.$attrs['numeric-placeholder'] && currentDialCode ? (countriesKeyedByDialCode?.[currentDialCode]?.placeholder || defaultPlaceholder) : defaultPlaceholder,
      }
    },
  },
  watch: {
    value(val) {
      if (val !== this.initialPhone) {
        // console.log('IN: PHONE UPDATED', val)
        this.initialPhone = val || ''
        this.updateCurrentValue()
      }
    },
    dialCode(val) {
      const finalValue = String(val).replace('+', '')
      if (val !== this.initialDialCode) {
        // console.log('IN: DIAL CODE UPDATED', finalValue)
        this.initialDialCode = finalValue || ''
        this.updateCurrentValue()
      }
    },
    currentPhoneObject(val) {
      // console.log('OUT: OBJECT UPDATED', val, val.nationalNumber || '')

      this.initialDialCode = val?.country?.dialCode || ''
      this.$emit('update:dial-code', this.initialDialCode)
      this.initialPhone = val.nationalNumber || ''
      this.$emit('input', this.initialPhone)
      this.$emit('update:country-code', val?.country?.iso2 || null)
      this.$emit('update:international-number', val.number)
    },
  },

  setup(props) {
    const currentPhoneObject = ref(null)
    const currentValue = ref('')
    const initialDialCode = ref(props.dialCode || '')
    const initialPhone = ref(props.value || '')
    const updateCurrentValue = () => {
      currentValue.value = `${initialDialCode.value && initialPhone.value ? `+${initialDialCode.value}` : ''}${initialPhone.value}`
    }
    updateCurrentValue()

    return {
      currentPhoneObject,
      currentValue,
      initialDialCode,
      initialPhone,
      updateCurrentValue,
    }
  },
  mounted() {
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.dark-layout .vti__selection .vti__country-code {
  color: #b4b7bd;
}
.vti__input.form-control {
  border: unset;
}

[dir] .dark-layout .vue-tel-input:not(:focus){
  border-color: #404656;
}
//[dir] .dark-layout .vue-tel-input:focus {
//  background-color: #fff;
//  border-color: #00c99c !important;
//  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
//}
/* Invalid state */
[dir] .is-invalid .vue-tel-input{
  border-color: #ea5455 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}

[dir="rtl"] .is-invalid .vue-tel-input {
  padding-left: calc(1.45em + 0.876rem);
  background-position: left calc(0.3625em + 0.219rem) center;
}
[dir="ltr"] .is-invalid .vue-tel-input {
  padding-right: calc(1.45em + 0.876rem);
  background-position: right calc(0.3625em + 0.219rem) center;
}
[dir] .vue-tel-input:focus-within {
  border-color: #00c99c !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}
[dir] .ltr {
  direction: ltr;
}
</style>
