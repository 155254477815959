import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import announcements from '@/store/announcements'
import app from './app'
import appConfig from './app-config'
import storeConfig from './store-config'
import storeSettings from './store-settings'
import verticalMenu from './vertical-menu'
import editorMenu from './editor-menu'
import themeEditor from './theme-editor'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    storeConfig,
    storeSettings,
    verticalMenu,
    editorMenu,
    themeEditor,
    announcements,
    'app-ecommerce': ecommerceStoreModule, // TODO: To be removed
  },
  strict: process.env.DEV,
})
