<template>
  <quill-editor
    :id="$attrs.id"
    v-model="currentValue"
    :name="$attrs.id"
    :state="errors.length > 0 ? false:null"
    v-bind="$attrs"
    :options="snowOption"
    v-on="$listeners"
  ><slot /></quill-editor>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],
            // [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            // [{ 'header': 1 }, { 'header': 2 }],
            [{ align: [] }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['link', /*'image', */'video'],
            ['clean'],
          ],
        },
        placeholder: this.$attrs.placeholder,
      },
      currentValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
  },
  mounted() {
    const toolbarTooltips = {
      bold: 'Bold (ctrl+B)',
      italic: 'Italic (ctrl+I)',
      underline: 'Underline (ctrl+U)',
      strike: 'Strike',
      font: 'Select a font',
      size: 'Select a font size',
      header: 'Select the text style',
      color: 'Select a text color',
      background: 'Select a background color',
      script: {
        sub: 'Subscript',
        super: 'Superscript',
      },
      list: {
        ordered: 'Numbered list',
        bullet: 'Bulleted list',
      },
      indent: {
        '-1': 'Decrease indent',
        '+1': 'Increase indent',
      },
      direction: {
        rtl: 'Text direction (right to left | left to right)',
        ltr: 'Text direction (left ro right | right to left)',
      },
      align: 'Text alignment',
      link: 'Insert a link',
      image: 'Insert an image',
      formula: 'Insert a formula',
      clean: 'Clear format',
      'add-table': 'Add a new table',
      'table-row': 'Add a row to the selected table',
      'table-column': 'Add a column to the selected table',
      'remove-table': 'Remove selected table',
      help: 'Show help',
    }

    const showTooltip = (which, el) => {
      let tool = ''
      if (which === 'button') {
        tool = el.className.replace('ql-', '')
      } else if (which === 'span') {
        tool = el.className.replace('ql-', '')
        tool = tool.substr(0, tool.indexOf(' '))
      }
      if (tool) {
        // if element has value attribute, handling is different
        // buttons without value
        if (el.value === '') {
          if (toolbarTooltips[tool]) {
            el.setAttribute('title', toolbarTooltips[tool])
          }
        // buttons with value
        } else if (toolbarTooltips[tool] && typeof el.value !== 'undefined') {
          if (toolbarTooltips[tool][el.value]) el.setAttribute('title', toolbarTooltips[tool][el.value])
        // default
        } else el.setAttribute('title', toolbarTooltips[tool])
      }
    }
    // const showTooltip = el => {
    //   const tool = el.className.replace('ql-', '')
    //   if (tooltips[tool]) {
    //     console.log(tooltips[tool])
    //     el.setAttribute('title', tooltips[tool])
    //   }
    // }

    const toolbarElement = document.querySelector('.ql-toolbar')
    if (toolbarElement) {
      toolbarElement.querySelectorAll('button')
        .forEach(el => { showTooltip('button', el) })

      // for submenus inside
      toolbarElement.querySelectorAll('.ql-toolbar > span > span')
        .forEach(el => { showTooltip('span', el) })
    }
    //
    // const toolbarElement = document.querySelector('.ql-toolbar')
    // if (toolbarElement) {
    //   toolbarElement.querySelectorAll('button')
    //     .forEach(el => { showTooltip(el) })
    // }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
[dir] .quill-editor.is-invalid  {
  border: 1px solid #ea5455;
  border-radius: 0.357rem;
}
</style>
