import axios from '@axios'
import { env, storagePath } from '@core/utils/utils'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

const BACKEND_HOST = env('VUE_APP_BACKEND_API_HOST', 'http://api.localhost')
const MEDIA_UPLOAD_ENDPOINT = env('VUE_APP_MEDIA_UPLOAD_ENDPOINT', `${jwtDefaultConfig.DASHBOARD_ENDPOINT}/media`)
const FILE_UPLOAD_ENDPOINT = env('VUE_APP_FILE_UPLOAD_ENDPOINT', `${jwtDefaultConfig.DASHBOARD_ENDPOINT}/file`)
const mediaWriteUrl = `${BACKEND_HOST}/${MEDIA_UPLOAD_ENDPOINT}`
const fileWriteUrl = `${BACKEND_HOST}/${FILE_UPLOAD_ENDPOINT}`
const mediaReadUrl = storagePath()

export const upload = (_, fileObj, isImage = true) => new Promise((resolve, reject) => {
  const formData = new FormData()
  formData.append('media', fileObj)
  axios
    .post(isImage ? mediaWriteUrl : fileWriteUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(response)
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => reject(error))
})

export default {
  upload,
}

/*
export const useFileStore = defineStore('FileStore', {
  actions: {
    // 👉 Upload File
    upload(fileObj) {
      const formData = new FormData()
      formData.append('media', fileObj)

      return new Promise((resolve, reject) => {
        axios.post('http://cdn.localhost/media/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-STORE': context.shop.id,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
})
*/
