<template>
  <div>

    <b-form-input
      :id="$attrs.id"
      ref="inputRef"
      v-model="currentValue"
      :placeholder="$attrs.title"
      :state="errors.length > 0 ? false:null"
      :options="inputConfig"
      type="text"
      v-bind="$attrs"
    />
  </div>
<!--  v-bind="$attrs"-->
<!--  v-on="$listeners"-->

<!--    <br>-->
<!--    <code>-->
<!--      Props: {{ $props }}-->
<!--      <br>-->
<!--      Attrs: {{ $attrs }}-->
<!--      <br>-->
<!--      Listeners: {{ $listeners }}-->
<!--    </code>-->
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import { computed } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  components: {
    BFormInput,
  },
  emits: ['formatted-value-changed'],
  props: {
    // id: {
    //   type: String,
    //   default: null,
    // },
    // title: {
    //   required: true,
    //   type: String,
    // },
    value: {
      type: [String, Number, Array],
      default: null,
    },
    currency: {
      type: String,
      default: 'EGP',
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    errors: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      currentValue: this.value,
      currentFormattedValue: null,
    }
  },
  computed: {
    inputRefs() {
      return null
    },
  },
  watch: {
    value(val) {
      this.setValue(val)
      // this.currentValue = val
      // this.currentFormattedValue = this.formattedValue
      //
      // this.$emit('formatted-value-changed', this.formattedValue)
      //
      // console.log('Price value updated >>', this.$attrs.title, {
      //   val,
      //   formattedValue: this.formattedValue,
      //   numberValue: this.numberValue,
      // })
    },
    currency(val) {
      this.inputConfig.currency = val
      this.setOptions(this.inputConfig)
    },
    currentValue(val) {
      this.$emit('formatted-value-changed', val)
    },
  },

  setup(props, vm) {
    const inputConfig = computed(() => ({
      locale: props.locale,
      currency: props.currency,
      currencyDisplay: 'symbol',
      valueRange: {
        min: vm.attrs.min || 0,
        max: vm.attrs.max || 1e13,
      },
      precision: 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      valueScaling: false,
      useGrouping: true,
      accountingSign: false,
    }))

    const {
      inputRef, setValue, formattedValue, numberValue, setOptions,
    } = useCurrencyInput(inputConfig.value)

    return {
      inputRef, inputConfig, formattedValue, numberValue, setOptions, setValue,
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
</style>
