import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import themeSectionModel from '@/views/models/theme-editor/themeSectionModel'
import themeBlockItemModel from '@/views/models/settings/layout-settings/themeBlockItemModel'
import { isDevelopment, shop } from '@core/utils/utils'
import themeSectionOptionModel from '@/views/models/theme-editor/themeSectionOptionModel'
import themePageModel from "@/views/models/theme-editor/themePageModel";

const {
  fetchAllChildren: fetchAllSections,
  fetchOne: fetchOneSection,
  createChild: createSection,
  update: updateSection,
  deleteOne: deleteSection,
  // deleteMany,
  // sorting,
} = useModelStoreModule(
  themeSectionModel,
)

const {
  fetchOne: fetchThemePageWithSection,
} = useModelStoreModule(
  themePageModel,
)

const {
  fetchAllChildren: fetchAllSectionOptions,
  fetchOne: fetchOneSectionOption,
} = useModelStoreModule(
  themeSectionOptionModel,
)

const {
  // fetchAll,
  // fetchOne: fetchBlockItem,
  // create: createBlockItem,
  // update: updateBlockItem,
  // deleteMany: deleteBlockItems,
  // deleteOne: deleteBlockItem,
  sorting: saveSectionBlockItemsSorting,
} = useModelStoreModule(themeBlockItemModel)

export default {
  namespaced: true,
  state: {
    theme: 'start',
    page: 'index',
    previewMode: 'desktop',
    preSections: [],
    sortableSections: [],
    postSections: [],
    sections: [],
    settingSections: [],
    activeSection: null,
    activeSectionId: null,
    hoveredSectionId: null,
    sectionSettings: [],
    sectionBlocks: [],
    sectionOptions: [],
    inspectorIframe: null,
    sessionId: parseInt(Math.random() * 1e10, 10),
    postInspectorCallback: (e, v) => {},
  },
  getters: {
    selectedSection: state => state.activeSectionId,
    allSections: state => state.settingSections.concat(state.preSections).concat(state.sortableSections).concat(state.postSections),
    iframeWidth: state => {
      switch (state.previewMode) {
        // case 'desktop':
        //   return `${parseInt(1.78 * window.innerHeight, 10)}px`
        //   return '764px'
        case 'mobile':
          return '480px'
        default:
          return '100%'
      }
    },
    iframeUrl: state => {
      let storeDomain = shop().url
      if (isDevelopment()) {
        storeDomain = storeDomain.replace('https://', 'http://')
      }
      switch (state.page) {
        case 'product':
          return `${storeDomain}product/example-product?editor-session=${state.sessionId}`
        case 'collection':
          return `${storeDomain}shop?editor-session=${state.sessionId}`
        default:
          return `${storeDomain}?editor-session=${state.sessionId}`
      }
    },
  },
  mutations: {
    UPDATE_THEME(state, val) {
      state.theme = val
    },
    UPDATE_PAGE(state, val) {
      state.page = val
    },
    UPDATE_PREVIEW_MODE(state, val) {
      state.previewMode = val
    },
    UPDATE_PRE_SECTIONS(state, val) {
      state.preSections = val
    },
    UPDATE_SORTABLE_SECTIONS(state, val) {
      state.sortableSections = val
    },
    UPDATE_POST_SECTIONS(state, val) {
      state.postSections = val
    },
    UPDATE_GENERAL_SETTINGS_SECTIONS(state, val) {
      state.settingSections = val
    },
    UPDATE_ACTIVE_SECTION(state, val) {
      state.activeSection = val
    },
    UPDATE_ACTIVE_SECTION_ID(state, val) {
      state.activeSectionId = val
    },
    // UPDATE_HOVERED_SECTION_ID(state, val) {
    //   state.hoveredSectionId = val
    // },
    UPDATE_SECTION_SETTINGS(state, val) {
      state.sectionSettings = val
    },
    UPDATE_SECTION_BLOCKS(state, val) {
      state.sectionBlocks = val
    },
    UPDATE_SECTION_OPTIONS(state, val) {
      state.sectionOptions = val
    },
    UPDATE_INSPECTOR_IFRAME(state, val) {
      state.inspectorIframe = val
    },
    UPDATE_POST_INSPECTOR_CALLBACK(state, val) {
      state.postInspectorCallback = val
    },
    POST_TO_INSPECTOR(state, payload) {
      if (!state.postInspectorCallback) {
        return
      }

      state.postInspectorCallback(payload.event, payload.value)
    },
  },
  actions: {
    loadSections: (ctx, params = {}) => {
      if (params.theme) {
        ctx.commit('UPDATE_THEME', params.theme)
      }
      if (params.page) {
        ctx.commit('UPDATE_PAGE', params.page)
      }

      ctx.dispatch('loadSectionOptions') // We need to always have section options available when page sections loaded

      return fetchThemePageWithSection(ctx, `${ctx.state.theme}/${ctx.state.page}`).then(response => {
        ctx.commit('UPDATE_GENERAL_SETTINGS_SECTIONS', response.settingSections)
        ctx.commit('UPDATE_PRE_SECTIONS', response.preSections)
        ctx.commit('UPDATE_SORTABLE_SECTIONS', response.sortableSections)
        ctx.commit('UPDATE_POST_SECTIONS', response.postSections)
        return response
      })
    },
    saveSectionBlocksSorting: (ctx, payload) => saveSectionBlockItemsSorting(ctx, { section_id: payload.sectionId, items: payload.data.map((itemId, displayOrder) => ({ id: itemId, display_order: displayOrder + 1 })) }).then(response => {
      ctx.commit('POST_TO_INSPECTOR', { event: 'sectionUpdated', value: ctx.state.activeSectionId })
      return response
    }),
    loadSection: (ctx, sectionId) => {
      ctx.commit('UPDATE_ACTIVE_SECTION_ID', sectionId)
      ctx.commit('POST_TO_INSPECTOR', { event: 'sectionSelection', value: sectionId })
      return fetchOneSection(ctx, `${ctx.state.theme}/sections/${sectionId}`).then(response => {
        ctx.commit('UPDATE_SECTION_SETTINGS', response.settings)
        ctx.commit('UPDATE_SECTION_BLOCKS', response.blocks)
        ctx.commit('UPDATE_ACTIVE_SECTION', {
          id: response.id,
          slug: response.slug,
          name: response.name,
          is_new: response.is_new,
        })
        return response
      })
    },
    loadSectionUpdates: ctx => {
      ctx.commit('POST_TO_INSPECTOR', { event: 'sectionUpdated', value: ctx.state.activeSectionId })
      ctx.dispatch('loadSection', ctx.state.activeSectionId)
    },
    saveSectionSettings: (ctx, payload) => {
      const callback = payload.callback || (() => {})
      return updateSection(ctx, {
        id: `${ctx.state.theme}/sections/${ctx.state.activeSectionId}`,
        settings: payload.data,
      }).then(response => {
        ctx.commit('POST_TO_INSPECTOR', { event: 'sectionUpdated', value: ctx.state.activeSectionId })
        ctx.commit('UPDATE_SECTION_SETTINGS', response.settings)
        ctx.commit('UPDATE_SECTION_BLOCKS', response.blocks)
        callback(true)
        return response
      }).finally(() => {
        callback()
      })
    },
    updateSectionVisibility: (ctx, payload) => {
      const callback = payload.callback || (() => {})
      return updateSection(ctx, {
        id: `${ctx.state.theme}/sections/${payload.id}`,
        is_active: payload.is_active,
      }).then(response => {
        ctx.commit('POST_TO_INSPECTOR', { event: 'sectionUpdated', value: payload.id })
        ctx.dispatch('loadSections')
        callback(true)
        return response
      }).finally(() => {
        callback()
      })
    },
    clearSectionSelection: ctx => {
      ctx.commit('POST_TO_INSPECTOR', { event: 'sectionSelectionCleared', value: null })
      ctx.commit('UPDATE_ACTIVE_SECTION_ID', null)
      ctx.commit('UPDATE_ACTIVE_SECTION', null)
      ctx.commit('UPDATE_SECTION_SETTINGS', [])
      ctx.commit('UPDATE_SECTION_BLOCKS', [])
    },
    selectPage: (ctx, page) => {
      // ctx.commit('POST_TO_INSPECTOR', { event: 'sectionSelectionCleared', value: null })
      ctx.dispatch('clearSectionSelection', page)
      ctx.commit('UPDATE_PAGE', page)
      ctx.dispatch('loadSections', {
        theme: ctx.state.theme,
        page,
      }).then(() => {
        // If only one section, automatically open settings of it.
        if (ctx.state.sections.length === 1) {
          const sectionSlug = ctx.state.sections[0].slug
          ctx.commit('UPDATE_GENERAL_SETTINGS_SECTIONS', [])
          ctx.commit('UPDATE_PRE_SECTIONS', [])
          ctx.commit('UPDATE_SORTABLE_SECTIONS', [])
          ctx.commit('UPDATE_POST_SECTIONS', [])
          ctx.dispatch('loadSection', sectionSlug)
        }
      })
    },
    loadSectionOptions: ctx => fetchAllSectionOptions(ctx, `${ctx.state.theme}`, 'section-options', {}).then(response => {
      ctx.commit('UPDATE_SECTION_OPTIONS', response.data.data)
      return response
    }),
    addSection: (ctx, payload) => createSection(ctx, {
      id: `${ctx.state.theme}/${ctx.state.page}`,
      childResourceName: 'sections',
      payload,
    }).then(response => {
      ctx.commit('POST_TO_INSPECTOR', { event: 'sectionAdded', value: response.id })
      ctx.dispatch('loadSections')
      return response
    }),
    deleteSection: (ctx, sectionId) => deleteSection(ctx, `${ctx.state.theme}/sections/${sectionId}`).then(response => {
      ctx.commit('POST_TO_INSPECTOR', { event: 'sectionDeleted', value: sectionId })
      ctx.dispatch('loadSections')
      return response
    }),
  },
}
