<template>
  <div>
    <v-select
      :id="$attrs.id"
      v-model="currentValue"
      :state="errors.length > 0 ? false:null"
      v-bind="$attrs"
      input-id="$attrs.id"
      :placeholder="$attrs.placeholder || `${$t('Select')} ${$attrs.title}`"
      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
      v-on="$listeners"
    >
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name"
      />

      <template
        v-if="infoOption"
        #list-header
      >
        <li
          class="cursor-default d-flex justify-content-start my-50 text-info"
          style="padding: 7px 20px;"
        >
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-25"
          />
          <small
            class="align-middle "
          >{{ infoOption }}</small>
        </li>
      </template>
      <template
        v-if="colorOptions"
        #option="{ name_en, name_ar, internal_value }"
      >
        <span class="align-items-center">
          <i
            class="color-swatch d-inline-block mr-50 align-middle"
            :style="`background-color: ${internal_value}`"
          />
          <span class="align-middle">{{ locale === 'ar' ? name_ar : name_en }}</span>
        </span>
      </template>
      <template
        v-else-if="nestedOptions"
        #option="option"
      >
        <template v-if="option.level > 1">
          <span
            v-for="i in (option.level-2)"
            :key="i"
          >&nbsp;</span>
          <span v-if="$i18n.locale != 'ar'">└─</span>
          <span v-else>┘─</span>
        </template>
        <span>
          {{ option.label }}
        </span>
      </template>
      <template
        v-if="nestedOptions"
        #selected-option="{ full_name }"
      >
        {{ full_name }}
      </template>
      <template
        v-if="noEmptySlot"
        #no-options
      >
        <span />
      </template>
      <div
        v-else
        slot="no-options"
        v-t="'Sorry, no matching options.'"
      />
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { getLocale } from '@core/utils/utils'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
    // Special props. Listed here for reference
    noEmptySlot: {
      type: Boolean,
      default: false,
    },
    colorOptions: {
      type: Boolean,
      default: false,
    },
    nestedOptions: {
      type: Boolean,
      default: false,
    },
    infoOption: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
      locale: getLocale(),
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
  },
  setup(args, args2) {
    // console.log('On SelectInput setup args', args, args2)
  },
}
</script>

<style lang="scss">
[dir=ltr] .is-invalid .v-select .vs__dropdown-toggle, [dir=ltr] .is-invalid.v-select .vs__dropdown-toggle {
  border-color: #ea5455;
  padding-right: calc(0.75em + 1.657rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e") center right 1rem/calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) no-repeat;
}
[dir=rtl] .is-invalid .v-select .vs__dropdown-toggle, [dir=rtl] .is-invalid.v-select .vs__dropdown-toggle {
  border-color: #ea5455;
  padding-left: calc(0.75em + 1.657rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e") center left 1rem/calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) no-repeat;
}
[dir=rtl] .v-select, [dir=rtl] .v-select input {
  text-align: right;
}
// Color Swatch
.color-swatch {
  position: relative;
  background: transparent none;
  background-size: auto;
  min-width: 15px !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}
</style>
