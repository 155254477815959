<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <platform-logo />
      <h2 class="brand-text text-primary ml-1">
        {{ platformName() }}
      </h2>
      <small
        v-if="platformVersionName()"
        class="pl-50"
      >
        {{ platformVersionName() }}
      </small>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <template v-if="isMaintenance">
          <h2 class="mb-1">
            {{ this.$t('errors.server_maintenance.title') }}️
          </h2>
          <p class="mb-2">
            {{ this.$t('errors.server_maintenance.text') }}
          </p>
        </template>
        <template v-else>
          <h2 class="mb-1">
            {{ errorTitle || this.$t('errors.server_not_reachable.title') }}️
          </h2>
          <p class="mb-2">
            {{ errorDescription || this.$t('errors.server_not_reachable.text') }}
          </p>
        </template>


        <b-button
          v-if="retryCallback"
          v-t="`Try again`"
          variant="primary"
          class="mb-2 btn-sm-block"
          @click="tryAgain()"
        />

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { platformName, platformVersionName } from '@core/utils/utils'

export default {
  name: 'ServerUnreachable',
  components: {
    PlatformLogo,
    BLink,
    BButton,
    BImg,
  },
  props: {
    isMaintenance: {
      type: Boolean,
      default: () => false,
    },
    errorTitle: {
      type: String,
      default: () => null,
    },
    errorDescription: {
      type: String,
      default: () => null,
    },
    retryCallback: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    platformVersionName,
    platformName,
    tryAgain() {
      this.retryCallback()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
