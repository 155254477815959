<template>
  <div>
    <p
      v-if="error"
      class="text-danger"
    >
      {{ error }}
    </p>
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-overlay
            :show="isLoading"
            class="d-inline-block"
          >
            <b-img
              :id="$attrs.id"
              ref="previewEl"
              rounded
              :src="previewImg || src || placeholderImage"
              :height="$attrs.imgHeight || 80"
              :class="$attrs.imgClass"
              :style="$attrs.imgStyle"
            />
          </b-overlay>
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t('inputs._image.select') }}
        </b-button>

        <!--      v-model="currentValue"-->
        <b-form-file
          ref="refInputEl"
          accept=".jpg, .png, .jpeg, .gif, .webp"
          :hidden="true"
          plain
          @input="onFileSelected"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          type="button"
          @click="onClear"
        >
          {{ $t('Delete') }}
        </b-button>
        <!--/ reset -->
        <b-card-text>{{ description }}</b-card-text>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BButton, BCardText, BFormFile, BImg, BLink, BMedia, BMediaAside, BMediaBody, BOverlay,
} from 'bootstrap-vue'
import { ref, watch } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { upload } from '@/views/models/common/useFileStore'

import { storagePath } from '@core/utils/utils'

const URL = window.URL || window.webkitURL
const imgObject = new Image()

export default {
  components: {
    BMediaAside,
    BCardText,
    BMediaBody,
    BFormFile,
    BLink,
    BMedia,
    BImg,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: [String, null],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
    description: {
      type: [String, null],
      default: null,
    },
    autoUpload: {
      type: Boolean,
      default: true,
    },
    sizeLimit: {
      type: Number,
      default: 5 * 1024 * 1024, // 1MB
    },
    maxWidth: {
      type: Number,
      default: 2800,
    },
    maxHeight: {
      type: Number,
      default: 1800,
    },
  },
  data() {
    return {
      myFiles: [],
      currentValue: this.value || null,
      coverId: null,
    }
  },

  computed: {
    src() {
      return this.value ? storagePath(this.value) : null
    },
  },
  watch: {
  },
  created() {
    //
  },
  methods: {
    updateValue() {
      /*
      this.currentValue = this.$refs.pond.getFiles().filter(image => image.status === 2 || image.status === 5).map(file => file.serverId)
      this.$listeners.change(this.currentValue)

      // Update cover image
      const cover = this.$refs.pond.getFiles().filter(image => image.status === 2 || image.status === 5)?.[0]
      if (cover && this.coverId !== cover.id) {
        this.coverId = cover.id
        document.getElementsByClassName('filepond--item').forEach(elm => {
          if (elm.id === `filepond--item-${cover.id}`) {
            elm.classList.add('filepond--cover')
          } else {
            elm.classList.remove('filepond--cover')
          }
        })
      }
       */
    },
  },
  setup(props, ctx) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const previewImg = ref(null)
    const localeValue = ref(null)
    const isFileUploadInProgress = ref(false)
    const isUploadFailed = ref(false)
    const errorMsg = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, newValue => { previewImg.value = newValue })

    const mediaReadUrl = storagePath()

    const onClear = () => {
      previewImg.value = null
      localeValue.value = null
      refInputEl.value.files = []
      ctx.emit('input', null)
    }

    const onFileSelected = fileObj => {
      isFileUploadInProgress.value = true
      isUploadFailed.value = false

      if (fileObj && fileObj.size) {
        if (fileObj.size > props.sizeLimit) {
          isFileUploadInProgress.value = false
          isUploadFailed.value = true
          errorMsg.value = 'File size is larger than allowed limit'
          previewImg.value = null
          return
        }

        const objectUrl = URL.createObjectURL(fileObj)
        imgObject.src = objectUrl
        imgObject.onload = function () {
          URL.revokeObjectURL(objectUrl)
          if (this.width > props.maxWidth || this.height > props.maxHeight) {
            isFileUploadInProgress.value = false
            isUploadFailed.value = true
            errorMsg.value = `Please select image of dimensions less than ${props.maxWidth}*${props.maxHeight}`
            previewImg.value = null
          } else {
            inputImageRenderer()

            upload(ctx, fileObj)
              .then(response => {
                if (response.data.success !== true) {
                  throw new Error('File upload failed')
                }

                localeValue.value = response.data.data
                isFileUploadInProgress.value = false
              })
              .catch(error => {
                console.log(error)
                isFileUploadInProgress.value = false
                isUploadFailed.value = true
                errorMsg.value = null // TODO: Fill with error message
                previewImg.value = null
              })
          }
        }
      } else {
        isFileUploadInProgress.value = false
        isUploadFailed.value = false
        errorMsg.value = null
      }
    }

    const onReset = () => {
      previewImg.value = null
      localeValue.value = props.value
      refInputEl.value.files = []
    }

    // File upload
    // const selectedFile = ref(null)
    const t = ref(null)

    // watch(() => refInputEl.value, uploadSelectedFile)
    watch(() => props.value, () => {
      onReset()
    })
    watch(() => localeValue.value, newValue => {
      ctx.emit('input', newValue)

      // TODO: In backend, handle image settings: Change path and append prefix @media:
    })

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      onFileSelected,
      onReset,
      onClear,
      previewImg,
      localeValue,
      isLoading: isFileUploadInProgress,
      isFailed: isUploadFailed,
      error: errorMsg,
      placeholderImage: require('@/assets/images/pages/eCommerce/placeholder-general.webp'),
    }
  },
}
</script>

<style lang="scss">
</style>
