<template>
  <b-form-checkbox
    :id="$attrs.id"
    v-model="currentValue"
    :name="$attrs.id"
    :switch="isSwitch"
    :state="errors.length > 0 ? false:null"
    inline
    v-bind="$attrs"
    v-on="$listeners"
  ><slot /></b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    // id: {
    //   type: String,
    //   default: null,
    // },
    value: {
      type: [Boolean],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
  },
  setup(args, args2) {
    // console.log('On TextInput setup args', args, args2)
  },
}
</script>

<style lang="scss">
</style>
