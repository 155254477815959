<template>
  <b-form-input
    :id="$attrs.id"
    v-model="currentValue"
    :placeholder="$attrs.placeholder || $attrs.title"
    :state="errors.length > 0 ? false:null"
    v-bind="$attrs"
    v-on="$listeners"
  />
<!--  TODO: Test (:class="$attrs.inputBindings.class")-->

<!--    <br>-->
<!--    <code>-->
<!--      Props: {{ $props }}-->
<!--      <br>-->
<!--      Attrs: {{ $attrs }}-->
<!--      <br>-->
<!--      Listeners: {{ $listeners }}-->
<!--    </code>-->
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
  },
  props: {
    // id: {
    //   type: String,
    //   default: null,
    // },
    // title: {
    //   required: true,
    //   type: String,
    // },
    value: {
      type: [String, Number, Array, null],
      default: null,
    },
    errors: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      currentValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue
    },
  },
  setup(args, args2) {
    // console.log('On TextInput setup args', args, args2)
  },
}
</script>

<style lang="scss">
</style>
