import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData')
      && localStorage.getItem('storeData')
      && localStorage.getItem('storeId')
      && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const forgetLoginData = (tokens = []) => {
  // Remove token data
  tokens.forEach(token => {
    localStorage.removeItem(token)
  })

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Remove storeData from localStorage
  localStorage.removeItem('storeData')
  localStorage.removeItem('storeId')
}

export const setUserData = userData => localStorage.setItem('userData', JSON.stringify(userData))

export const getUserData = () => {
  try {
    return JSON.parse(localStorage.getItem('userData'))
  } catch (e) {
    forgetLoginData()
    return null
  }
}

export const setStoreData = storeData => {
  if (storeData) {
    localStorage.setItem('storeId', storeData.id)
    localStorage.setItem('storeData', JSON.stringify(storeData))
  }
}

export const getStoreData = () => JSON.parse(localStorage.getItem('storeData'))

export const isServerDown = () => localStorage.getItem('serverDown') === '1'
export const markServerAsUp = () => localStorage.removeItem('serverDown')
export const markServerAsDown = () => localStorage.setItem('serverDown', '1')
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
