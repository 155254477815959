export default [
  // *===============================================---*
  // *--------- Coupons -------------------------------*
  // *===============================================---*
  {
    path: '/coupons/list',
    name: 'coupons.list',
    component: () => import('@/views/models/coupons/list/CouponList.vue'),
    meta: {
      pageTitle: 'modules.coupons.p_c',
      breadcrumb: [
        {
          text: 'modules.coupons.pages.list',
          active: true,
        },
      ],
    },
  },
]
