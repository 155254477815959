import router from '@/router'
// eslint-disable-next-line object-curly-newline
import Vue, { reactive, getCurrentInstance, watch, toRefs, ref } from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const appendInProgressRequest = requestId => {
  if (typeof (Vue.$inProgressRequests) === 'undefined') {
    Vue.$inProgressRequests = ref([])
  }
  Vue.$inProgressRequests.value.push(requestId)
}

export const removeInProgressRequest = requestId => {
  if (!requestId) return
  Vue.$inProgressRequests.value = Vue.$inProgressRequests.value.filter(rid => rid !== requestId)
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

// /**
//  * This is just enhancement over Object.extend [Gives deep extend]
//  * @param {target} a Object which contains values to be overridden
//  * @param {source} b Object which contains values to override
//  */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

// Alert methods
/**
 * Display toast alert
 * @param type = [primary, secondary, success, danger, warning, info, dark]
 * @param title
 * @param text
 * @param icon
 * @param position
 * @param bodyLines
 */
export const toast = (type = 'success', title, text = null, icon = 'InfoIcon', position = 'top-right', bodyLines = []) => {
  Vue.$toast({
    component: ToastificationContent,
    position: position || 'top-right',
    props: {
      title,
      icon: icon || 'InfoIcon',
      variant: type,
      text,
      bodyLines,
    },
  },
  {
    // timeout,
  })
}

export const handleValidationError = (error, t) => {
  if (error?.response?.status !== 422) {
    return false
  }

  const errors = []
  Object.values(error.response.data.errors).forEach(attributesErrors => {
    Object.values(attributesErrors).forEach(attributeErrors => {
      if (typeof (attributeErrors) === 'object') {
        Object.values(attributeErrors).forEach(attributeError => {
          errors.push(attributeError)
        })
      } else {
        errors.push(attributeErrors)
      }
    })
  })
  toast(
    'warning',
    t('message.generic_validation_error'),
    null,
    'AlertTriangleIcon',
    null,
    errors,
  )
  return true
}
export const handleBadRequestError = (error, t) => {
  const statusCode = error?.response?.status
  if (!statusCode || (statusCode >= 500) || (statusCode < 400)) {
    return false
  }

  const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'

  toast(
    'warning',
    title,
    error.response?.data?.message,
    'AlertTriangleIcon',
    null,
  )
  return true
}

// --------------- Localization
export const getLocale = () => localStorage.getItem('locale') || 'ar'// TODO: src/libs/i18n/index.js
export const getLocaleCurrency = () => (localStorage.getItem('store.currency') && localStorage.getItem('store.currency') !== 'null' ? localStorage.getItem('store.currency') : 'SAR')
export const mediaPath = () => (localStorage.getItem('store.media') && localStorage.getItem('store.media') !== 'null' ? localStorage.getItem('store.media') : '')
// export const privateMediaPath = () => 'http://api.localhost/dashboard/media/'
// export const localeAttr = attr => `${attr}_${getLocale()}`

// export const storagePath = (path, def) => (path ? `http://demo.localhost/media/${path}` : def)
export const storagePath = (path, def) => (path ? mediaPath().replace('%', path.replace('@media:', '')) : def)

// eslint-disable-next-line no-use-before-define
export const getSupportedStoreLanguages = () => shop()?.languages || ['ar', 'en']
export const getLanguagesList = () => {
  const supportedStoreLanguages = getSupportedStoreLanguages()
  const langs = [
    {
      name: 'English',
      native: 'English',
      slug: 'en',
    },
    {
      name: 'Arabic',
      native: 'عربي',
      slug: 'ar',
    },
  ].filter(l => supportedStoreLanguages.includes(l.slug))

  if (getLocale() === 'ar') {
    return langs.reverse()
  }
  return langs
}

// -------------- General
export const env = (key, fallback) => process.env[key] || fallback
export const shop = () => JSON.parse(localStorage.getItem('storeData')) || {}
export const canUseFeature = feature => !!(shop().feature_flags || []).find(x => x === feature)
export const platformName = () => (getLocale() === 'ar' ? env('VUE_APP_PLATFORM_NAME_AR') : env('VUE_APP_PLATFORM_NAME_EN'))
export const platformVersionName = () => ''// (getLocale() === 'ar' ? 'تجريبي' : 'Beta')
export const platformUrl = () => env('VUE_APP_LANDING_PAGE_URL')
export const dashboardHost = () => env('VUE_APP_DASHBOARD_HOST', 'localhost:8080')
export const demoHost = () => env('VUE_APP_DEMO_HOST', 'demo.localhost:8080')
export const isDemoHost = () => window.location.host === demoHost()
export const isDevelopment = () => process.env.NODE_ENV === 'development'
export const isProductionDebug = () => localStorage.storeId === '9ab85982-ab2f-4836-9102-80dfc8679de1'
export const isUnitTestMode = () => ((process.env.NODE_ENV === 'development') && (!!window.Cypress))

export const catchException = e => {
  if (isDevelopment()) {
    console.error('Exception occurred', e)
  }
}

export const getCookie = cname => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

// -------------- Tracking
export const trackEvent = (name, data = {}) => {
  if (isDevelopment()) {
    return
  }
  try {
    if (typeof (window.fbq) !== 'undefined') {
      window.fbq('track', name, data)
    }
  } catch (e) {
    console.error(e)
  }
}
