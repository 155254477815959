export default {
  name: 'theme_section_blocks',

  translatedAttributes: [],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => {
    const payload = item
    const settings = {}
    Object.keys(payload.settings || {}).forEach(k => {
      if (!k) {
        return
      }

      settings[k] = payload.settings[k]
    })
    if (Object.keys(settings).length) {
      payload.settings = settings
    }
    return payload
  },

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    ...data,
    // id: data.block_id,
    // block_template_id: data.block_template_id,
    // settings: data.settings,
    // is_active: !!data.is_active,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    // TODO: Display each setting error
    settings: errors.settings,
    is_active: errors.is_active,
  }),
}
