import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import announcementsModel from '@/views/models/announcements/announcementsModel'

const {
  fetchAll,
  update,
} = useModelStoreModule(announcementsModel)

export default {
  namespaced: true,
  state: {
    announcements: null,
  },
  getters: {},
  mutations: {
  },
  actions: {
    fetchAll,
    update,
  },
}
