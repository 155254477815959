<template>
  <span
    class="material-symbols-outlined"
    :style="`color: ${color}; font-size:${size}px; vertical-align:${align}`"
    v-bind="$attrs"
    v-on="$listeners"
  >{{ icon }}</span>
</template>

<script>
export default {
  name: 'MaterialIcon',
  props: {
    icon: {
      required: true,
      type: String,
    },
    color: {
      type: String,
      default: 'inherit',
    },
    size: {
      type: String,
      default: '24',
    },
    align: {
      type: String,
      default: 'middle',
    },
  },
}
</script>

<style lang="scss">
</style>
